import React, { useRef } from 'react'
import { Search, Offers, OfferHeadlineComponent } from '../'

import { BioMiniTeaser, ClientRender, LayoutBackground } from '../shared'
import { getLocale } from '../../utils/localeUtils'
import * as styles from '../../css/atom/bioOfferOverview.module.scss'
export const OfferOverview = ({
  data,
  isPreview,
  location,
  isCurrentOffer,
  dividingBannerContent
}) => {
  const previewCountMarketNoGlobal =
    data?.sanityGetPreviewCountMarketNoGlobal?.totalCount
  const previewCountGlobal = data?.sanityGetPreviewCountGlobal?.totalCount

  const marketOffers = data?.sanityMarketOffers?.nodes
  const globalOffers = data?.sanityAllOffers?.nodes
  const allOfferData = isPreview
    ? data?.sanityAllOffers?.nodes
    : data?.noLimitOffers?.nodes
  const offerValidTo = allOfferData[allOfferData.length - 1]?.validto

  const getUniqueOffers = () => {
    const difference = globalOffers.filter(
      item => !marketOffers.find(offer => item._id === offer._id)
    )
    difference.forEach(item => marketOffers.unshift(item))
    marketOffers.sort((a, b) => new Date(a.validfrom) - new Date(b.validfrom))

    return marketOffers
  }

  const offers =
    data?.sanityMarketOffers?.totalCount > 0 ? getUniqueOffers() : globalOffers

  const {
    miniTeaser,
    filterButtonText,
    legalDisclaimer,
    _rawNoSearchResultsText
  } = isPreview
    ? data.sanityOfferPreviewPage.nodes[0]
    : data.sanityOfferPage.nodes[0]

  const layoutBackgroundHeaderComponent = (
    <div className={styles.bioOfferOverviewHeader}>
      <OfferHeadlineComponent
        data={
          isPreview
            ? data.sanityOfferPreviewPage.nodes[0]
            : data.sanityOfferPage?.nodes[0]
        }
        market={data.sanityMarketData}
        publishDates={data.sanityAllPublishDates.nodes}
        eightPagerGroup={data.eightPagerGroup}
        isPreview={isPreview}
        offers={offers}
        previewCountMarketNoGlobal={previewCountMarketNoGlobal}
        previewCountGlobal={previewCountGlobal}
        offerValidTo={offerValidTo}
        isCurrentOffer={isCurrentOffer}
        dividingBannerContent={dividingBannerContent}
      />
    </div>
  )

  const locale = getLocale()
  const searchResultRef = useRef()
  return (
    <>
      <div className={styles.bioOfferOverview}>
        <ClientRender>
          <BioMiniTeaser cards={miniTeaser} />
        </ClientRender>
      </div>
      <LayoutBackground headerComponent={layoutBackgroundHeaderComponent}>
        <section ref={searchResultRef} className="relative">
          <Search
            index="offer"
            marketId={data.marketId !== 'noMarket' && data.marketId}
            isOfferPreview={isPreview}
            typeOfCount="Angebote"
            count={offers?.length}
            initialItems={offers}
            filterButtonText={filterButtonText}
            showToTopButton
            searchListHeight={searchResultRef?.current?.offsetHeight}
            previewCountMarketNoGlobal={previewCountMarketNoGlobal}
            previewCountGlobal={previewCountGlobal}
            locale={locale}>
            <Offers
              market={data?.sanityMarketData}
              _rawNoSearchResultsText={_rawNoSearchResultsText}
              noButton
              location={location}
              legal={legalDisclaimer}
              isPreview={isPreview}
              offers={offers}
              offerValidTo={offerValidTo}
              locale={locale}
            />
          </Search>
        </section>
      </LayoutBackground>
    </>
  )
}
